/*.DraftEditor-editorContainer h5 > div > span {
  text-align: center !important;
}
*/


.title {
  font-style: normal !important;
  font-weight: bold;
  letter-spacing: -0.16px;
  line-height: 26px;
}

.title span {
  font-weight: bold;
  font-style: normal !important;
}


.subtitle {
  font-style: normal !important;
  font-weight: 500;
  line-height: 22px;
}

.subtitle span {
  font-weight: 500;
  font-style: normal !important;
}

.customBody {
  font-style: normal !important;
  font-weight: 400;
  line-height: 22px;
}

.customBody span {
  font-weight: 400 !important;
  font-style: normal !important;
}

[dir="rtl"] .title {
  font-weight: bold;
  letter-spacing: -0.16px;
  line-height: 26px;
}

[dir="rtl"] .subtitle {
  font-weight: 500;
  line-height: 22px;
}


[dir="rtl"] .customBody {
  font-weight: 400;
  line-height: 20px;
}

[dir="rtl"] .rdw-dropdown-selectedtext {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}

.public-DraftStyleDefault-block {
   margin: 6px 0 !important
}

.public-DraftStyleDefault-orderedListItem {
  font-weight: 400;
  line-height: 22px;
  font-size: 14px;
}

