.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.congratsContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  svg {
    margin-bottom: 50px;
  }

  span {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.cardProduct {
  max-width: 900px;
  margin: auto;
}

.toolbarClassName {
  margin-top: 10px;
  margin-bottom: 0 !important;
  border: 1px solid #e3e3e3 !important;
  border-bottom: 0 !important;
}

.editorClassName {
  padding: 0 10px;
  border: 1px solid #e3e3e3;
  min-height: 125px;
  overflow: visible !important;
}

.disabled{
  opacity: 0.5;
  border: 1px solid rgb(92, 88, 88) !important;

}

.toolbarClassName.disabled{
  display:none !important
}
.editorClassName.disabled{
  margin-top: 20px;
}