@import 'node_modules/react-datepicker/src/stylesheets/datepicker.scss';
@import 'node_modules/react-datepicker/src/stylesheets/variables.scss';

.react-datepicker__day--in-range {
  background-color: #87D3D3 !important;
  color: #251938 !important
}

.react-datepicker__day {
  color: #251938 !important;
  font-weight: 400 !important;
  font-family: "Montserrat";
}

.react-datepicker__day--selected {
  background-color: #69B5B5 !important;
}

.react-datepicker__day--range-start {
  background-color: #69B5B5 !important;
}

.react-datepicker__day--range-end {
  background-color: #69B5B5 !important;
}

.react-datepicker__day--today {
  font-weight: normal !important;
}

.react-datepicker__header {
  background-color: #ffff !important;
  border-bottom: none !important;
}

.react-datepicker__day-name {
  color: #251938 !important;
  font-weight: 500 !important;
  font-family: "Montserrat";
}

.react-datepicker {
  border-color: #fff !important;
}

[dir="rtl"]  .react-tel-input .form-control {
  margin-right: 5px;
}

[dir="rtl"]  .react-tel-input .flag-dropdown .arrow {
  right: 20px;
}

[dir="rtl"]  .react-tel-input .flag-dropdown .flag {
  right: 5px;
}


