@font-face {
    font-family: 'FF Shamel Family';
    src: url('./fonts/ff_shamel_family_sans_one_book.woff') format('woff'),
    url('./fonts/ff_shamel_family_sans_one_book.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

[dir='rtl'] [data-id='caret-right'] {
  transform: rotateZ(180deg);
}

[dir="rtl"] input[type=text]
{
  font-family: 'FF Shamel Family' !important;
}

.Toastify__toast-container--bottom-center {
  top:50%
}